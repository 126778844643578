*{
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  text-rendering: geometricPrecision;
}

html{
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;

  @media screen and (min-width: 960px){
    font-size: 78.125%;
  }
}

body{
  box-sizing: border-box;
  color: $gray1;
  font-family: $base-font;
  font-size: $font-size;
  line-height: $line-height;
  min-height: 100%;
  position: relative;
}

h1, h2, h3, h4, h5, h6{
  font-family: $heading;
  font-weight: 500;
}

h1, h2, h3{
  line-height: 4.8rem;
  padding: .8rem 0 1.6rem;
}

h4, h5, h6{
  padding: 2.4rem 0;
}

h1{
  font-size: 3.6rem;
}

h2{
  font-size: 3.2rem;
}

h3{
  font-size: 2.8rem;
}

h4{
  font-size: 2.0rem;
}

h5{
  font-size: 1.8rem;
}

h6{
  font-size: 1.6rem;
}

p{
  padding-bottom: 2.4rem;
}

form{
  padding: 2.4rem 0;
}

pre,
code,
samp,
kbd{
  background-color: $gray6;
  font-size: $font-size--small;
  color: $primary-dark;
  font-family: $monospace;
  line-height: 1;
}

code,
samp,
kbd{
  border-radius: 2px;
  overflow-y: hidden;
  overflow-x: auto;
  padding: .4rem .4rem .2rem;
}

pre{
  border-radius: 2px;
  line-height: $line-height;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 1.2rem 1rem;
  margin: 0 0 2.4rem;

  code,
  samp,
  kbd{
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
  }
}

a:link{
  color: $link;
}

a:visited{
  color: $link--visited;
}

a:hover,
a:focus{
  color: $link--hover;
}

a:active{
  color: $link--active;
}

img,
video{
  max-width: 100%;
  display: table;
}

hr{
  height: 4.8rem;
  margin-bottom: 2.4rem;
  position: relative;

  &:before{
    background-color: $gray4;
    content: '';
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

ol,
ul{
  margin-left: 2rem;
  padding: 0 0 2.4rem;
}

ol{
  list-style-type: decimal;
}

ol ol{
  padding-bottom: 0;
}

ul{
  list-style-type: disc;
}

ul ul{
  padding-bottom: 0;
}

dt{
  font-style: italic;
}
dd{
  text-indent: 2rem;
}

blockquote{
  margin: 0 0 2.4rem 1.2rem;
  border-left: .3rem solid $gray4;
  padding: 2.4rem 1.2rem;

  p:last-child{
    padding-bottom: 0;
  }
}


figure{
  margin: 0 auto;
  padding-bottom: 2.4rem;

  img{
    display: table;
    margin: 0 auto;
  }
}

small,
sub,
sup{
  font-size: $font-size--small;
  line-height: 1;
}

caption,
figcaption{
  font-size: $font-size--small;
  font-style: italic;
  text-align: center;
}

em,
cite,
i{
  font-style: italic;
}

strong,
var,
b{
  font-weight: bold;
}

q{
  &:before{
    content: "'";
  }

  &:after{
    content: "'";
  }

  & > q{
    font-style: italic;
  }
}

dfn,
abbr{
  border-bottom: .1rem dotted $gray2;
  cursor: default;
}

table{
  border-radius: 2px;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 2.4rem;
  width: 100%;

  th{
    font-weight: 500;
    text-align: left;
  }

  th, td{
    padding: 0 .6rem;
  }
}

label{
  color: $gray3;
}

input,
label,
select,
button,
textarea
{
  display: block;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* remove yellow from chrome auto-fill background */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  color: $gray1;
  -webkit-text-fill-color: $gray1;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
