// Font based variables
//
// Styleguide

$base-font:       'Avenir', 'Avenir Next','Helvetica Neue','Segoe UI', 'Verdana', sans-serif !default;
$monospace-font:  'Consolas', 'Monaco', 'Courier New', monospace !default;

$heading:         $base-font !default;
$body:            $base-font !default;
$monospace:       $monospace-font !default;

$font-size: 1.6rem !default;
$line-height: 2.4rem !default;

$font-size--small: 1.2rem !default;
$font-size--large: 2rem !default;
